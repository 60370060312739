import VueRouter from 'vue-router';

import RouterView from '../cmp/router-view.vue';
import LoginPage from '../pages/account/account-login.vue';

// NOTE: these two are static, maybe better for performance
import VoucherActivationPage from '../pages/voucher-activation.vue';

// NOTE: lazy loading pages and components

const AccountManagePage = () => import('../pages/account/account-manage.vue');
const AccountPersonalInfo = () => import('../pages/account/account-profile.vue');

const CompanyMngListPage = () => import('../pages/company-management/company-mng-list.vue');
const CompanyEditPage = () => import('../pages/company-management/company-edit.vue');
const CompanyCIPage = () => import('../pages/company-management/company-ci.vue');
const CompanyMngPage = () => import('../pages/company-management/company-mng.vue');

const CampaignMngListPage = () => import('../pages/campaign/campaign-mng-list.vue');
const CampaignCreatePage = () => import('../pages/campaign/campaign-create.vue');
const CampaignEditPage = () => import('../pages/campaign/campaign-edit.vue');

const SystemSettingsPage = () => import('../pages/system-settings.vue');

const UserManagePage = () => import('../pages/user/user-management.vue');
const UserListPage = () => import('../pages/user/user-list.vue');
const UserEdit = () => import('../pages/user/user-edit.vue');
const UserPassword = () => import('../pages/user/user-password.vue');

const router = new VueRouter({
  // Customize scroll behavior, always to top because of Safari
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  // NOTE: using html5 history mode
  mode: 'history',
  routes: [
    {
      /* the voucher page for purchaser per company */
      path: '/:slugName?',
      name: 'voucherActivation',
      component: VoucherActivationPage,
    },
    {
      /* the login component is at the root */
      path: '/admin/login',
      name: 'loginPage',
      component: LoginPage,
    },
    // account public pages
    {
      // the parent page for all tabbed pages
      path: '/admin/profile',
      redirect: '/admin/profile/personal-info',
      component: AccountManagePage,
      children: [
        {
          path: 'personal-info',
          name: 'accountPersonalInfoPage',
          component: AccountPersonalInfo,
          meta: {
            authorities: ['CA', 'SA'],
          },
        },
      ],
    },
    {
      path: '/admin/users',
      component: RouterView,
      meta: {
        authorities: ['SA'],
      },
      children: [
        {
          path: '',
          component: UserListPage,
          name: 'userListPage',
          meta: {
            authorities: ['SA'],
          },
        },
        {
          path: 'create',
          name: 'userCreatePage',
          component: UserEdit,
          meta: {
            authorities: ['SA'],
          },
        },
        {
          path: 'manage/:id',
          component: UserManagePage,
          children: [
            {
              path: 'edit',
              name: 'userEditPage',
              component: UserEdit,
              meta: {
                authorities: ['SA'],
              },
            },
            {
              path: 'password',
              name: 'userPassword',
              component: UserPassword,
              meta: {
                authorities: ['SA'],
              },
            },
          ],
        },
      ],
    },
    {
      path: '/admin/system',
      component: SystemSettingsPage,
      name: 'systemSettingsPage',
      meta: {
        authorities: ['SA'],
      },
    },
    {
      path: '/admin/companies',
      name: 'companyMngList',
      component: CompanyMngListPage,
      meta: {
        authorities: ['CA'],
      },
    },
    {
      path: '/admin/companies/:companyId',
      component: CompanyMngPage,
      meta: {
        authorities: ['CA'],
      },
      children: [
        {
          path: '/admin/companies/:companyId/edit',
          name: 'companyEdit',
          component: CompanyEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/admin/companies/:companyId/campaigns',
          component: CampaignMngListPage,
          name: 'campaignMngList',
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/admin/companies/:companyId/campaigns/:campaignId/edit',
          name: 'campaignEdit',
          component: CampaignEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/admin/companies/:companyId/ci',
          name: 'companyCI',
          component: CompanyCIPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/admin/companies/:companyId/campaign-create',
          name: 'campaignCreate',
          component: CampaignCreatePage,
          meta: {
            authorities: ['CA'],
          },
        },
      ],
    },
    {
      path: '/admin/companies/create',
      name: 'companyCreate',
      component: CompanyEditPage,
      meta: {
        authorities: ['CA'],
      },
    },
  ],
});

export default router;
