import { mapGetters } from 'vuex';

import constants from '../common/constants';

// const APP_URL_TPL_REGEX = /{(.+)}/;

const { SERVER_URL } = constants;

/**
 * Helper function to strip html characters form some string.
 * Used in cases when v-html or similar unescaped sensitive content is displayed and parts of user-entered text
 * require safe text.
 * @param {string} html - The html to clean.
 * @returns {string} - The cleaned string.
 */
function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

/**
 * The Mixin with system and platform related methods.
 */
export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          // PWA app update refresh variables
          refreshing: false,
          registration: null,
          updateExists: false,
          // NOTE: signal that API is updated, and that app update dialog needs to be shown, but
          // the action would be a page refresh in that case to trigger the worker service update
          apiUpdated: false,
        };
      },
      created() {
        // support for CUSTOM PWA software updated event
        // Listen for our custom event from the SW registration
        // A note about the once option; setting this option to true allows the listener to be called only once
        // AND removes the listener once invoked.
        document.addEventListener('swUpdated', this.updateAvailable, { once: true });

        // Prevent multiple refreshes
        if (navigator.serviceWorker) {
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return;
            this.refreshing = true;
            // Here the actual reload of the page occurs
            window.location.reload();
          });
        }
      },
      methods: {
        /**
         * The PWA app update is available so this is the handler.
         * Store the SW registration so we can send it a message.
         * We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
         * to alert the user there is an update they need to refresh for.
         * @param {CustomEvent} event - The custom event when app update is available.
         */
        updateAvailable(event) {
          console.log('pwa app update available...');
          this.registration = event.detail;
          this.updateExists = true;
        },
        /**
         * Triggered by axios interceptor - there is an API update, the user needs to click on OK in dialog.
         * NOTE: initiates the service worker update by API version update detection.
         */
        apiUpdateAvailable() {
          console.log('API app update available...');
          // thi will also show update acknowledge dialog
          this.apiUpdated = true;
        },

        /**
         * Refreshes the application PWA code based on available update.
         * Called when the user accepts the update.
         */
        refreshApp() {
          if (this.apiUpdated) {
            // The following will unregister all service workers for this app, and after that reload the page
            if (navigator.serviceWorker) {
              console.log('Service worker registered -> Unregistering all service workers and reloading...');
              navigator.serviceWorker
                .getRegistrations()
                .then((registrations) => Promise.all(registrations.map((r) => r.unregister())))
                .then(() => window.location.reload());
            } else {
              console.log('No service worker registered -> Reloading all...');
              window.location.reload();
            }
          } else {
            this.updateExists = false;
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return;
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          }
        },

        /**
         * Go to page with specified name.
         * @param {string} name - The vue router page name.
         * @param {object} options - The standard vue routing options when used with name.
         */
        goTo(name, options = {}) {
          // NOTE: in order to prevent vee validate issues for delayed validation, it is paused before routing.
          if (this.$validator) {
            this.$validator.pause();
          }
          this.$router.push({ name, ...options });
          // NOTE: for FULL reloading current route this could be a solution
          // this.$router.go(0);
        },
        /**
         * Custom go back method executed when go back button is clicked.
         * NOTE: please inject the 'accountService' to the component using this function. Otherwise it will not work.
         * Should be improved in future.
         */
        goBack() {
          // NOTE: in order to prevent vee validate issues for delayed validation, it is paused before routing.
          if (this.$validator) {
            this.$validator.pause();
          }
          // In case that the user came from some specific page, it should go back based on home page resolution
          // All exceptions should be configured when needed
          if ([null].indexOf(this.accountService().fromRoute.name) >= 0) {
            this.$router.push(this.accountService().homePageByAuth());
            return;
          }
          this.$router.go(-1);
        },
        /**
         * Evaluates the type of message key based on the key prefix.
         * @param {string} msgKey - The message key.
         */
        getMessageType(msgKey) {
          if (msgKey) {
            return msgKey.startsWith('message.') ? 'message' : 'error';
          }
          // if not specified return error to provoke the detection of the issue
          return 'error';
        },
        stripHtml,

        /**
         * Gets the path to web resource on server.
         * @param {string} relPath - The relative path, eg 'css/somestyle.css'.
         * @returns {string} - The server path to the resource.
         */
        getWebResUrl(relPath) {
          return `${SERVER_URL}${relPath}`;
        },
      },
      computed: {
        ...mapGetters(['sysInfo', 'currentLanguage']),

        /**
         * Evaluates client locale for use in client app, that might differ from the language defined in the model.
         * Eg for Serbian, the latin variant must be forced.
         */
        clientLocale() {
          const langKey = this.$store.getters.currentLanguage;
          if (langKey === 'sr') {
            return 'sr-Latn-RS';
          }
          return langKey;
        },

        /**
         * The global flag (stored as a route param) used to force the display of app notif messages in UI.
         * This way, after changing route it is possible to control the display.
         */
        showAppMsgs() {
          return this.$route.params.showAppMsgs;
        },

        isAdminRoute() {
          return this.$route.path.startsWith('/admin');
        },

        /**
         * Evaluates companyId present as route param.
         * @returns {number} - The id of company in the route.
         */
        companyId() {
          return this.$route.params.companyId;
        },
      },
    });
  },
};
