import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify/lib';
// import supported languages for Vuetify
// NOTE: the import of languages for vuetify is now done manually, can be improved similar to dynamic localization of vee validator
import en from 'vuetify/es5/locale/en';

// import sr from 'vuetify/es5/locale/sr-Cyrl';
// NOTE: for SRB, forcing the use of custom latin translations
import sr from '../i18n/vuetify-custom-locales/sr-Latn';

// import customized styles
import '../stylus/main.styl';

// import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
  defaultAssets: false,
  lang: {
    locales: { sr, en },
    // t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    // The primary color is used for toolbar and in variants with darker and lighter for other page elements
    // e.g. buttons are using lighter variant
    // NOTE: - the template color is replaced by the one from material palette

    // primary: '#00d4f9',
    primary: '#01B2FF',
    secondary: '#252525',
    accent: '#015587',
    // error: '#ff1c1c',
    // // using gray for info color - for now
    // info: '#808080',
    // // success: '#4CAF50',
    // success: '#3465A4',
    // warning: '#FF9800',

    border: 'a0a0a0',

    //--------
    // defining vars for custom colors
    // for order numbers in lists
    ordinal_number: '#9E9E9E',

    // the original primary for 'A' platform.
    aprimary: '#00d4f9',
    // a custom lighten1 variant of A primary
    aprimaryl1: '#e6fbff',
  },
  options: {
    // Enabling customProperties will also generate a css variable for each theme color, which you can then use in your components' <style> blocks.
    // e.g.
    // .something {
    //     color: var(--v-primary-base)
    //     background-color: var(--v-accent-lighten2)
    // }
    customProperties: true,
  },
  iconfont: 'fa',
  components: {
    // NOTE: a placeholder for alacarte?
    // VApp,
    // VNavigationDrawer,
    // VFooter,
    // VToolbar,
    // VFadeTransition,
    // VMenu,
  },
});
