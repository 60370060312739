import axios from 'axios';
import qs from 'query-string';

/**
 * The service for handling remote packs through avoucher app as a proxy.
 */
export default class RemotePackService {
  constructor({ store, router }) {
    this.store = store;
    this.router = router;
  }

  /**
   * Retrieves remote packs.
   * @param {object} options - The retrieve options.
   * @param {string} options.envKey - The envKey of the remote pack platform.
   * @returns {Promise<Array<object>>} - The array of remote packs.
   */
  async retrievePacks({ envKey }) {
    const queryOpts = { envKey };
    const res = await axios.get(`api/remote-packs?${qs.stringify(queryOpts)}`);
    return res.data;
  }

  async activatePack(frmDTO) {
    const res = await axios.post(`api/remote-pack-activate`, frmDTO);
    return res.data;
  }
}
